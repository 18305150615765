var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","elevation":"0"}},[_c('app-data-table',{ref:"refAppDataTable",attrs:{"uri":_vm.datatable.uri,"headers":_vm.datatable.headers,"filter-data":_vm.datatable.filter},scopedSlots:_vm._u([{key:"document_no_",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/purchasing/offer/detail?id=' + item.id}},[_vm._v(_vm._s(item.document_no_))])]}},{key:"tender_document_no_",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/purchasing/tender/detail?id=' + item.tender_header_id}},[_vm._v(_vm._s(item.tender_document_no_))])]}},{key:"vendor_name",fn:function(ref){
var item = ref.item;
return [_c('app-vendor-info',{attrs:{"vendor-id":item.vendor_id}},[_vm._v(_vm._s(item.vendor_name))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('app-document-status',{model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }