<template>
  <div>
    <v-container>
      <page-title title="Offer"></page-title>
      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-tabs v-model="tab">
              <v-tab>All</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ListData></ListData>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
export default {
  components: {
    ListData,
  },
  data() {
    return {
      tab: this.$store.state.activeTab['Purchasing.Offer.Index'],
      dialog: null,

      form_data: {
        type: '',
      },

      form_error: {
        type: null,
      },

      types: [
        'Project',
        'Non-Project'
      ],

      valid: true,
    };
  },

  watch: {
    tab(newVal) {
      this.$store.state.activeTab['Purchasing.Offer.Index'] = newVal
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async createDocument() {
      this.showLoadingOverlay(true);
      var uri = '/tender/create'
      const formData = new FormData();
      formData.append("document_type", this.form_data.document_type);
      await this.$axios.post(uri, formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          if (res.data.status == 'success') {
            this.redirect('Purchasing.Offer.Edit', { id: res.data.data.tender.id });
            return;
          }
          this.showAlert(res.data.status, res.data.message);
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.form_error = error.response.data.data.errors
        });
    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("tender", "view", true);
  },
};
</script>
